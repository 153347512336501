// Add class to related posts so they can use card prefab
if (typeof sidebarCards !== "undefined" && sidebarCards) {
  if ($("section.associated:not(.relatedProducts) .associatedListing li")) {
    $("section.associated:not(.relatedProducts) .associatedListing li").each(function () {
      if ($(this).css("display") != "none") {
        $(this).addClass("listedPost").addClass("sidebarCard");
        $(this).find("h3, p").wrapAll('<div class="listedPostText">');
        let thisURL = $(this).find("h3 a").attr("href");
        $(this).attr("onclick", `window.location = "${thisURL}"`);
        $(this)
          .find("h3")
          .replaceWith(function () {
            return "<h2>" + $(this).text() + "</h2>";
          });
      }
    });
  }
}
