// This will wrap feed titles in a link so the user can navigate to see 'all news' (for example)
// See https://github.com/RaisingITDev/fresco/wiki/feed-title-links

if (
  typeof feedTitleLinks !== 'undefined' &&
  feedTitleLinks &&
  ($('body').hasClass('homepage') && !$('body').hasClass('subsite'))
) {
  feedTitleLinks.forEach(function(link, i) {
    var homeBoxClass = '.homeFeedBox' + (i + 1);
    $(homeBoxClass + ' .feedsTitle').wrapAll('<a href="' + link + '" />');
  });
}
