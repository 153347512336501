// Detect banner images
if (
  $(".carouselSlide img:visible, .carouselSlide.containsVideo:visible").length >
  0
) {
  $("body").addClass("page-has-banner");
} else {
  $("body").addClass("page-no-banner");
}

// Detect carousel posts with video banners
if ($(".carouselSlideTitle.showsVideo").length > 0) {
  $("body").addClass("page-has-video-banner");
}

// Detect whether listing has featured post or not
if ($('#bodyForm section.listing').length > 0) {
  if ($('.post00000000000000000000000000000000').length > 0 || $('body.appealsList.inMemType').length > 0) {
    $('body').addClass('listing-without-feature')
  } else {
    $('body').addClass('listing-with-feature')
  }
}

// The carouselControls should never be larger than the banner image
var carouselControls = $(".carouselControls");
var bannerImage = $(
  ".carouselSlide:first-child img:not(.donationAmountThumbnail)"
).first;

function maxHeightCarouselMeta() {
  var bannerHeight = bannerImage.height;
  carouselControls.css("max-height", bannerHeight);
}

maxHeightCarouselMeta();

$(document).ready(function() {
  maxHeightCarouselMeta();
});

$(window).on("scroll resize", function() {
  maxHeightCarouselMeta();
});

// Lift header text out of carousel
if ($(".carousel .headerText").length > 0) {
  $(".carousel").each(function() {
    var $thisHeaderText = $(this).find(".headerText");
    $(this)
      .parent()
      .append($thisHeaderText);
  });
}

// Add a 'scroll' button to carousel
$(".carousel").append(
  '<a class="carouselScrollBtn" href="#afterCarousel" title="Scroll down to start of content">Scroll</a>'
);
$('<a id="afterCarousel" />').insertAfter(".carousel");

// Remove the 'close' button from Vimeo video popups
// With the way that the Vimeo iframe gets added to the page,
// it's actually pretty impossible to get the close button to sit properly.
// Removing it feels safe enough, because you can click anywhere outside the
// video frame to close it.
$(document).ready(function() {
  $("#iframeVimeo").each(function() {
    $(this).prev(".videoPopupClose").remove();
  });
});
