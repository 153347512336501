if (
  typeof carouselBackgroundVideoYouTubeID !== 'undefined' &&
  carouselBackgroundVideoYouTubeID &&
  ($('body').hasClass('homepage') && !$('body').hasClass('subsite'))
) {
  $('.carouselSlide:first-child .carouselSlideTitle').append(
    '<div class="yt-container"><iframe src="https://www.youtube.com/embed/' +
      carouselBackgroundVideoYouTubeID +
      '?controls=0&rel=0&autoplay=1&mute=1&loop=1&disablekb=1&fs=0&modestbranding=1&playlist=' +
      carouselBackgroundVideoYouTubeID +
      '" frameborder="0" allowfullscreen></iframe></div>'
  );
}
