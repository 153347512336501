// See components/appeals/_lual-appeals.scss for usage.

if (
  typeof lualBodyClasses !== 'undefined' &&
  lualBodyClasses
) {
  for (var i = 0; i < lualBodyClasses.length; i++) {
    if (lualBodyClasses[i].includes("Category_")) {
      var thisAppealBodyClass = lualBodyClasses[i];
    } else {
      var thisAppealBodyClass = "appeal-" + lualBodyClasses[i];
    }
    var thisDonateBodyClass = "donate-" + lualBodyClasses[i];
    if ($('body').hasClass(thisAppealBodyClass)) {

      // Set different values for different appeals
      var heightVals = {};

      if (typeof visualAppealsCustomHeightValues !== 'undefined' && typeof visualAppealsCustomHeightValues[lualBodyClasses[i]] !== 'undefined') {
        heightVals = visualAppealsCustomHeightValues[lualBodyClasses[i]];
      } else {

        // Appeal specific background position - Flowers appeal
        if ($('#bodyForm').css("background-image").indexOf("bg_meadow.jpg") != -1) {
          heightVals = {
            heightSm: "-500",
            heightMd: "-500",
            heightXl: "-500"
          }
        }

        // Appeal specific background position - Christmas appeal
        if ($('#bodyForm').css("background-image").indexOf("christmas-field.png") != -1) {
          heightVals = {
            heightSm: "-375",
            heightMd: "-585",
            heightXl: "-735"
          }
        }

        // Appeal specific background position - Star sky appeal
        if ($('#bodyForm').css("background-image").indexOf("light-up-a-life-bg.jpg") != -1) {
          heightVals = {
            heightSm: "-350",
            heightMd: "-675",
            heightXl: "-675"
          }
        }
      }


      // Run function on resize
      $(document).ready(function () {
        backgroundAdjustHeight();
        $(window).resize(function () {
          backgroundAdjustHeight();
        });
      });

      //  Calculate height of headerText
      function backgroundAdjustHeight() {
        var bodyForm = $('#bodyForm');
        var commentsListTop = $('.commentsList').offset().top;
        var bodyFormTop = $('#bodyForm').offset().top;
        var headerTextHeight = commentsListTop - bodyFormTop;

        if ($(window).width() <= 769) {
          $(bodyForm).css({
            'background-position-y': 'calc(' + heightVals.heightSm + 'px + ' + headerTextHeight + 'px)'
          });
        } else if ($(window).width() <= 1025) {
          $(bodyForm).css({
            'background-position-y': 'calc(' + heightVals.heightMd + 'px + ' + headerTextHeight + 'px)'
          });
        } else {
          $(bodyForm).css({
            'background-position-y': 'calc(' + heightVals.heightXl + 'px + ' + headerTextHeight + 'px)'
          });
        }
      }

      // Create a new comments list to print out the comments below the icon field
      $('.commentsListWrapper .content').append('<ul class="commentsList--printed" />');
      $('.appealContent > *:not(footer, aside)').insertAfter('.headerText h1.title');

      // Generate a random number
      function randomNum() {
        return Math.floor(Math.random() * 33);
      }

      // This is the function to actually position the icon
      function randomPositionIcon(icon) {
        var xPos = randomNum() * 3;
        var yPos = randomNum() * 3;

        // console.log(xPos + ', ' + yPos);
        $(icon).css('top', yPos + '%');
        $(icon).css('left', xPos + '%');

        // If we are super far left, add a class to style hover differently
        if (xPos < 18) {
          $(icon).addClass('commentDonation--hard-left');
        }

        // If we are super far right, add a class to style hover differently
        if (xPos > 82) {
          $(icon).addClass('commentDonation--hard-right');
        }

        // Add a class to fade in, and also mark as already positioned so we don't reposition it
        $(icon).addClass('commentDonation--positioned');
      }

      // Position our initial icons
      $('.commentDonation').each(function () {
        // First, let's copy each comment and paste it into the newly created 'printed' list below the icon field
        $(this).clone().appendTo('.commentsList--printed');
        // Then position this comment on the field
        randomPositionIcon($(this));
      });

      // Self calling timeout func to check if we need to load more icons
      (function clickLoadButton() {
        if ($('.button.loadMore:not(.hidden)').length > 0) {
          $('.button.loadMore').click();

          // Wait for a bit so we know we have the comments back from the data call
          setTimeout(function () {
            $('.commentsList .commentDonation:not(.commentDonation--positioned)').each(function () {
              $(this).clone().appendTo('.commentsList--printed');
              randomPositionIcon($(this));
            });
            updateDonationControls()
          }, 1000);
        } else {
          $('#donateCount').removeClass('loading');
        }
        setTimeout(clickLoadButton, 3000);
      })();

      function finalCommentNumber() {
        if (commentPageEnd <= $('.commentsList--printed li').length) {
          return commentPageEnd
        } else {
          return $('.commentsList--printed li').length
        }
      }

      function updateDonationControls() {
        $('#donateCount').text(`Showing ${commentPageStart + 1} – ${finalCommentNumber()} of ${$('.commentsList--printed li').length}  `);
        if (commentPageStart === 0) {
          $('#previousStars').css('opacity', '0');
          $('#previousStars').css('pointer-events', 'none');
        } else {
          $('#previousStars').css('opacity', '1');
          $('#previousStars').css('pointer-events', 'auto');
        }
        if (commentPageEnd >= $('.commentsList--printed li').length) {
          $('#nextStars').css('opacity', '0');
          $('#nextStars').css('pointer-events', 'none');
        } else {
          $('#nextStars').css('opacity', '1');
          $('#nextStars').css('pointer-events', 'auto');
        }
      }

      let visualDonationCount = 50;
      let commentPageStart = 0;
      let commentPageEnd = visualDonationCount;
      var style = document.createElement('style');
      if (typeof visualAppealsLoadMore !== 'undefined' &&
        visualAppealsLoadMore) {
        document.head.appendChild(style);

        function updateCSS() {
          style.innerHTML = `.commentDonation--positioned:not(:nth-of-type(n+${commentPageStart + 1}):nth-of-type(-n+${commentPageEnd})) { display: none !important; }`;
        }
        updateCSS();

        if ($('.button.loadMore:not(.hidden)').length > 0) {

          $('.commentsList').after(`
          <div class="donationControls">
            <button id="previousStars" style="opacity: 0; pointer-events: none;">Previous</button>
            <p id="donateCount" class="loading"></p>
            <button id="nextStars" style="opacity: 0; pointer-events: none;">Next</button>
          </div>
        `);
          $('#nextStars').click(function (e) {
            e.preventDefault();
            commentPageStart += visualDonationCount;
            commentPageEnd += visualDonationCount;
            updateCSS();
            updateDonationControls()
          });
          $('#previousStars').click(function (e) {
            e.preventDefault();
            commentPageStart -= visualDonationCount;
            commentPageEnd -= visualDonationCount;
            updateCSS();
            updateDonationControls()
          });
        };
      }

      break;
    }


    if ($('body').hasClass(thisDonateBodyClass)) {
      $(".formQuestion.donationComment > label").text("Leave a comment with your donation, please include the name of the person you are donating in memory of:");
    }
  }
}