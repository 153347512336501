// Image resize transform
function imageResize(img, width, height) {
  var updatedSrc = $(img)
    .attr('src')
    .replace(/w=([0-9]*)&h=([0-9]*)/, 'w=' + width + '&h=' + height) // No smart-cropping
    .replace(
      /width=([0-9]*)&height=([0-9]*)/,
      'width=' + width + '&height=' + height
    ); // Smart cropping
  $(img).attr('src', updatedSrc);
}

// Transform homepage quickgiving donation amount images if required
if (
  typeof transformHomepageDonationAmountImages !== 'undefined' &&
  transformHomepageDonationAmountImages &&
  $('body').hasClass('homepage') && $('.carouselSlide:first-child .carouselSlideTitle img').length == 1
) {
  var bannerUrl = $('.carouselSlide:first-child .carouselSlideTitle img').attr(
    'src'
  );

  var bannerWidthRegex;
  var bannerHeightRegex;

  if (bannerUrl.includes('cropped')) {
    // Smart crop enabled
    bannerWidthRegex = /width=(.*?)&/;
    bannerHeightRegex = /height=(...?)/;
  } else {
    // Smart crop disabled
    bannerWidthRegex = /w=(.*?)&/;
    bannerHeightRegex = /h=(.*?)&/;
  }

  var bannerWidth = bannerUrl.split(bannerWidthRegex)[1];
  var bannerHeight = bannerUrl.split(bannerHeightRegex)[1];
  var bannerAspectRatio = bannerWidth / bannerHeight;
  var responsiveTopPadding = 100 / bannerAspectRatio;

  $('.quickGivingPanel .donationAmount .checkboxRadio img').each(function() {
    imageResize(this, bannerWidth, bannerHeight);
  });

  var donationImagesTimer;
  var homeQuickGiving = $('.homefeaturecategory-homeboxquickgiving');
  var homeQuickGivingDesktopTopPadding = homeQuickGiving.css('padding-top');

  $(window).on('load resize', function() {
    clearTimeout(donationImagesTimer);
    donationImagesTimer = setTimeout(function() {
      if ($(window).width() <= 1024) {
        homeQuickGiving.css('padding-top', responsiveTopPadding + 'vw');
      } else {
        homeQuickGiving.removeAttr('style');
      }
    }, 10);
  });
}
