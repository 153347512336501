// Function we can use to check if an element is in view
$.fn.isInViewport = function() {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

// Function for 'in-view' class assignment
function isInView(element) {
  const $element = $(element);
  $(window).on('load resize scroll', function() {
    if ($element.isInViewport()) {
      $element.addClass('in-view');
    }
  });
}

// Fly in classes on features and feed cards
$('.homeFeature, .feedItem').each(function() {
  isInView(this);
});
