if ($('body').hasClass('homepage')) {
  // Lift out any snippet set to appear in 'home headlines intro'
  $('.homeHeadlinesIntro').prependTo('.homeFeaturesWrapper');

  // Lift our non-card homeboxes out of the container
  let homeFeatureCardDetected;

  $('.homeFeature').each(function() {
    const $this = $(this);

    // 1. Check if this is a "special" feature (i.e. stats, full width or quick giving)
    if (
      $this.hasClass('homefeaturecategory-homeboximpactstats') ||
      $this.hasClass('homefeaturecategory-homeboxfullwidth') ||
      $this.hasClass('homefeaturecategory-homeboxquickgiving')
    ) {
      // If so, check whether we have had any normal 'card' features yet
      if (!homeFeatureCardDetected) {
        // If not, then lift this special feature out and put it BEFORE the cards
        $this.prependTo('.homeFeaturesWrapper');
      } else {
        // If we have had a card feature, lift it out AFTER the cards
        $this.appendTo('.homeFeaturesWrapper');
      }
    } else {
      // Or if the feature we are looping through IS a card (i.e. doesn't have a special class), then set a global var to say we've had a card so future special features need to be inserted AFTER
      homeFeatureCardDetected = true;
    }
  });

  // Wrap Impact elements in a div to be able to max-width and place accordingly
  $('.homefeaturecategory-homeboximpactstats').wrapInner(
    "<div class='homeImpactWrapper' />"
  );

  // Move home quick giving pre-footer?
  if (
    typeof homeQuickGivingPreFooter !== 'undefined' &&
    homeQuickGivingPreFooter
  ) {
    $('.homefeaturecategory-homeboxquickgiving').insertBefore(
      '.pageFooterWrapper'
    );
  }

  // Wrap contents of feed item to match pattern from other cards
  $('.feedItem').each(function() {
    $(this)
      .find('> *:not(.home-feed-image-link):not(.postFooterWrapper)')
      .wrapAll('<div class="feedItemDetailsWrapper" />');
  });

  // Correct product feed item layout
  $('.homeFeedproducts .feedItem').each(function(){
    var $thisDetailWrapper = $(this).find('.feedItemDetailsWrapper');
    $(this).find('.feedItemDetailsWrapper > a:first-child').insertBefore($thisDetailWrapper);
  })

  // Homepage quick giving panel - wrap contents
  $('.homefeaturecategory-homeboxquickgiving > *:not(.mceNonEditable)').wrapAll(
    '<div class="homepage-quickgiving--copy" />'
  );
  $('.homefeaturecategory-homeboxquickgiving').wrapInner(
    '<div class="homepage-quickgiving--container" />'
  );
}
