// Sticky header
if (typeof stickyHeader !== 'undefined' && stickyHeader) {
  var pageHeader = $('#pageHeader');

  $(pageHeader).css({
    position: 'fixed',
    top: 0,
    'z-index': 3
  });

  function bodyAddMargin() {
    var pageHeaderHeight = pageHeader.height();
    $('body').css({
      'margin-top': pageHeaderHeight + 'px'
    });
  }

  // Run functions on load
  bodyAddMargin();

  var timer;

  $(window).on('load scroll resize', function() {
    clearTimeout(timer);
    timer = setTimeout(function() {
      var scrollTop = $(window).scrollTop();

      if (scrollTop > 0) {
        pageHeader.addClass('sticky');
      } else {
        pageHeader.removeClass('sticky');
      }

      bodyAddMargin();
    }, 10);
  });
}
