// If you set a feedItemsCarouselBreakpoint value, the feed item cards
// will turn into a carousel at this breakpoint. This can help to reduce
// the vertical height of the homepage on mobile, although it obviously
// massively deprioritises the feed items that aren't in the first position

if (
  typeof feedItemsCarouselBreakpoint !== 'undefined' &&
  feedItemsCarouselBreakpoint > 0 &&
  ($('body').hasClass('homepage') && !$('body').hasClass('subsite'))
) {
  if (typeof feedItemsCarouselAutoplay == 'undefined') {
    var feedItemsCarouselAutoplay = false;
  }

  if (typeof feedItemsCarouselAutoplaySpeed == 'undefined') {
    var feedItemsCarouselAutoplaySpeed = 0;
  }

  if (typeof feedMegaCarousel == 'undefined') {
    var feedMegaCarousel = [];
  }

  // Set a timer variable for setTimeout usage
  var feedItemsCarouselTimer;

  // Set a variable to store the state of whether Slick has been applied
  var feedItemsCarouselInitiated = false;

  $(window).on('load resize', function() {
    clearTimeout(feedItemsCarouselTimer);

    feedItemsCarouselTimer = setTimeout(function() {
      var windowWidth = $(window).width();

      if (windowWidth <= feedItemsCarouselBreakpoint) {
        if (feedItemsCarouselInitiated == false) {
          $('.homeFeed').each(function(i) {
            var homeFeedBoxClass = '.homeFeedBox' + (i + 1);

            // If this home feed is not set to be a mega carousel...
            if (!feedMegaCarousel.includes(homeFeedBoxClass)) {
              $(this)
                .find('.feedList')
                .slick({
                  infinite: true,
                  slidesToScroll: 1,
                  slidesToShow: 1,
                  centerMode: true,
                  arrows: false,
                  autoplay: feedItemsCarouselAutoplay,
                  autoplayspeed: feedItemsCarouselAutoplaySpeed
                });
            }
          });

          feedItemsCarouselInitiated = true;
        }
      } else if (feedItemsCarouselInitiated == true) {
        $('.homeFeed').each(function(i) {
          var homeFeedBoxClass = '.homeFeedBox' + (i + 1);

          // If this home feed is not set to be a mega carousel...
          if (!feedMegaCarousel.includes(homeFeedBoxClass)) {
            $(this)
              .find('.feedList')
              .slick('unslick');
          }
        });

        feedItemsCarouselInitiated = false;
      }
    }, 10);
  });
}
