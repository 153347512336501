// Lift out any tagline from header
$('.mainCallToAction .tagline').insertAfter('.mainLogo');

// Wrap header cta buttons
$('.mainCallToAction .cta-button').wrapAll(
  '<div class="mainCallToActionButtons" />'
);

// Clone header search and main cta snippet into nav for mobile
$('.mainCallToAction')
  .clone()
  .appendTo('.menuMain');
$('.header-search')
  .clone()
  .prependTo('.menuMain');
$('.menuMain #siteSearch').removeAttr('id');

// Move burger button into mainCTA
$('.menuMainAlt').appendTo('.headerContent .mainCallToAction');

// Clone admin items from header into footer links list
$('#menuAdmin li').each(function() {
  $(this)
    .clone()
    .appendTo('.footerBox ul.footerLinks');
});