// Animate homepage stats
if (typeof countUpStats !== "undefined" && countUpStats) {
  if ($("body").hasClass("homepage") && !$("body").hasClass("subsite") || $("body:not(.homepage)").hasClass("PostCategory_post-count-up-stats")) {
    // Using a setTimeout so we're not firing this every single frame of scroll
    let statsTimer;

    $(window).on("load resize scroll", function () {
      clearTimeout(statsTimer);
      statsTimer = setTimeout(function () {
        $(".homefeaturecategory-homeboximpactstats td:first-child > *:first-child, .postContent > table td:first-child > *:first-child").each(function () {
          if (
            $(".homefeaturecategory-homeboximpactstats, .postContent > table").length &&
            !$(this).hasClass("animated") &&
            $(".homefeaturecategory-homeboximpactstats, .postContent > table").isInViewport()
          ) {
            const element = $(this);
            const originalText = element.text();
            let elementText = originalText;

            const firstChar = elementText.charAt(0);
            const lastChar = elementText.slice(-1);
            let prefix = "";
            let suffix = "";

            // Add class to stop animation triggering again
            $(this).addClass("animated");

            // Check to see if we have a countUpStatsDuration variable
            // If so, use that value for the animation swing below
            // Otherwise, let's use a default value of 3000
            const animationDuration =
              typeof countUpStatsDuration !== "undefined" &&
              countUpStatsDuration ?
              countUpStatsDuration :
              3000;

            if (isNaN(firstChar)) {
              prefix = firstChar;
              elementText = elementText.substring(1, elementText.length);
            }

            if (isNaN(lastChar)) {
              suffix = lastChar;
              elementText = elementText.slice(0, -1);
            }

            // Strip any "," values
            elementText = elementText.replace(/,/g, "");

            // Count length of any decimal places
            const mantissaLength = elementText.split(".")[1] ?
              elementText.split(".")[1].length :
              0;

            $({
              countNum: 0
            }).animate({
                countNum: elementText,
              },

              {
                duration: animationDuration,
                easing: "swing",
                step: function (now) {
                  element.text(
                    prefix + parseFloat(now).toFixed(mantissaLength) + suffix
                  );
                },
                complete: function () {
                  element.text(originalText);
                },
              }
            );
          }
        });

      }, 10);
      
    });
  }
}