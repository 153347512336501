// This will turn the specified feed(s) into a 100% wide mega carousel

if (
  typeof feedMegaCarousel !== 'undefined' &&
  feedMegaCarousel &&
  ($('body').hasClass('homepage') && !$('body').hasClass('subsite'))
) {

  feedMegaCarousel.forEach(function(feed, i) {
    var homeFeedBoxClass = '.homeFeedBox' + (i + 1);

    $(homeFeedBoxClass).addClass('homeFeedMegaCarousel');

    $(homeFeedBoxClass + ' .feedList').slick({
      infinite: true,
      slidesToShow: 1
    });
  });
}
