// Lift subsite body/intro text out of parent
$('.headerTextSubsite > *:not(.subsiteTitle)').wrapAll(
  '<div class="subsiteBody" />'
);
$('.subsiteBody').insertAfter('.headerTextSubsite');

// Subsite title link back to subsite home
if (typeof subsiteTitleNotClickable == 'undefined' || subsiteTitleNotClickable == false) {
  $('.subsiteTitle').on('click', function () {
    // Get the subsite slug from the subsite banner image - usually hidden from view on internal subsite pages but it's still there for us to leverage
    // This is more reliable than trying to get the subsite slug from body classes
    window.location.href = $(
      '.carouselSubsite .carouselSlide:first-child a'
    ).attr('href');
  });
} else {
  $('.subsiteTitle').on('click', function () {
    null
  });
}

// Similar click event for main logo if needed
if (
  typeof subsiteHijackMainLogoClickDestination !== 'undefined' &&
  subsiteHijackMainLogoClickDestination &&
  $('body').hasClass('subsite')
) {
  const $body = $('body');
  const $logo = $('.mainLogo');

  subsiteHijackMainLogoClickDestination.forEach(function (subsite) {
    // 1. Check we are on the subsite
    if ($body.hasClass(subsite)) {
      // 2. Update the href of the main logo to the subsite slug
      $logo.attr('href', '/' + subsite);
    }
  })
}

if (
  typeof subsiteHijackMainNav !== 'undefined' &&
  subsiteHijackMainNav &&
  $('body').hasClass('subsite')
) {
  const $body = $('body');

  subsiteHijackMainNav.forEach(function (subsite) {
    // 1. Check if we are on a subsite selected for nav hijacking
    if ($body.hasClass(subsite)) {
      // 2. Remove the main site's navigation
      $('#menuMain .topLevel').remove();
      // 3. Move the subsite nav into where the main nav was
      $('#menuSub .topLevel').insertBefore('#menuMain .mainCallToAction');
      // 4. Hide the now redundant menuSub nav
      $('#menuSub').remove();
    }
  });
}