// Close nav item's family if another item is openend
$("ul.topLevel a").click(function () {
    $(this).parent().siblings().find("a").removeClass('active');
    $(this).parent().siblings().find(".subMenu").removeClass('active');
});

// Set a max-height on the active mobile menu so that it is always stretched to the bottom of the viewport
$(".menuMainAlt").on("click", function() {
  var menuMain = $(".menuMain");

  // If we are opening the menu
  if (!menuMain.hasClass("active")) {
    var pageHeaderHeight = $("#pageHeader").height();
    menuMain.css("max-height", `calc(${window.innerHeight}px - ${pageHeaderHeight}px)`);
  } else {
    menuMain.css("max-height", "0");
  }
});

// Change nav max-height if window size changes
$(window).on("resize", function() {
  if ($('.menuMain').hasClass('active')) {
    var pageHeaderHeight = $("#pageHeader").height();
    $('.menuMain.active').css("max-height", `calc(${window.innerHeight}px - ${pageHeaderHeight}px)`);
  }
})

// Clone in a button to close flyout and fullscreen navs
if (
  (typeof flyoutNav !== "undefined" && flyoutNav) ||
  (typeof fullscreenNav !== "undefined" && fullscreenNav)
) {
  $(".menuMainAlt")
    .clone()
    .prependTo(".menuMain");
}

// Close flyout nav if you click anywhere but the open nav
if (
  (typeof flyoutNav !== "undefined" && flyoutNav) 
) {
  $("body").on("click", function(e) {
    if ($(e.target).is("#menuMain, .menuMainAlt")) {
      return;
    }
    if ($(e.target).parents("#menuMain").length === 0) {
      $("#menuMain, .menuMainAlt").removeClass("active");
    }
  });
}

// Add a container for fullscreen nav contents
if (typeof fullscreenNav !== "undefined" && fullscreenNav) {
  $(".menuMain").wrapInner('<div class="menuMainContents" />');
}

// Add chevron when megaMenu
if (typeof megaMenu !== 'undefined' && megaMenu) {

  $("li.level1:has(.subMenu)").mouseenter( function() {
    $(this).find('> a').addClass('active');
  });

  $("li.level1:has(.subMenu)").mouseleave( function() {
    $(this).find('> a').removeClass('active');
  });
}

// Detect whether submenus on a normal nav need to extend out to the left
// Firstly, we don't want to ever run this block if we're using a flyoutNav or fullscreenNav
if (
  (typeof flyoutNav == "undefined" || !flyoutNav) &&
  (typeof fullscreenNav == "undefined" || !fullscreenNav) &&
  (typeof megaMenu == "undefined" || !megaMenu)
) {
  function overflowSubmenusFlyoutLeft() {
    var $menuMainAlt = $(".menuMainAlt");

    setTimeout(function() {
      // Check whether we are pre-nav breakpoint
      if ($menuMainAlt.css("display") === "none") {
        var windowWidth = $(window).width(); // Get our window width
        var subMenuWidth = $(".subMenu.level2")
          .first()
          .css("width"); // Get the width of a submenu
        var subMenuWidthInt = parseInt(subMenuWidth, 10); // Convert that px width value to an int for maths

        // Loop over our top-level items
        $(".level1").each(function() {
          var $this = $(this); // Rather than JQuery select it every time
          var offSet = $this.offset(); // Get co-ords within the window for our top level item
          var level2SubMenu = $this.children("ul.level2"); // Our level2 submenus...
          var level3SubMenus = $this.find("ul.level3"); // level3s...
          var level4SubMenus = $this.find("ul.level4"); // and level4s.

          // If the level2 is going to break out the right of the window
          if (offSet.left + subMenuWidthInt >= windowWidth) {
            // Then 'align it' with the right hand side of the top level item
            level2SubMenu.css({ left: "auto", right: "0" });
          } else {
            level2SubMenu.css({ left: "0", right: "auto" });
          }

          // If level3 items within this top level item are going to break out the window (i.e. 2 widths of a submenu)
          if (offSet.left + subMenuWidthInt * 2 >= windowWidth) {
            // Then set level3s and 4s to expand out to the left
            level3SubMenus.css("left", "-99%");
            level4SubMenus.css("left", "-99%");

            // Any list items that have submenus need this class to toggle chevron behaviour
            $this.find(".hasSubmenu:not(.level1)").addClass("hasSubmenuLeft");
          }

          // Or if level4s in this top level item would break out the window...
          else if (offSet.left + subMenuWidthInt * 3 >= windowWidth) {
            level4SubMenus.css("left", "-99%"); // Set them to fly out from the left of their parent

            // Toggle chevron behaviour of level4 parents
            level4SubMenus
              .parents(".hasSubmenu:not(.level1)")
              .addClass("hasSubmenuLeft");

            level3SubMenus.each(function() {
              // And then ALSO any level3s that have level4 children
              if ($(this).find("ul.level4").length > 0) {
                $(this).css("left", "-99%");
              }
            });
          }

          // Or, if no level4s or 3s are going to break out the window, just set them to the default behaviour
          else {
            level3SubMenus.css("left", "100%");
            level4SubMenus.css("left", "100%");

            // Clear altered chevron behaviour
            $this.find(".hasSubmenu").removeClass("hasSubmenuLeft");
          }
        });
      }
    }, 10);
  }
  $(window).on("load resize", function() {
    overflowSubmenusFlyoutLeft();
  });
  $(document).ready(function(){
    overflowSubmenusFlyoutLeft();
  })
}

// Open menuMain on hover
if (typeof hoverSensitiveNav !== 'undefined' && hoverSensitiveNav) {
  $('.headerContent .menuMainAlt').mouseenter( function() {
    setTimeout(function() {
      $('.headerContent .menuMainAlt, .menuMain, .menuMain .menuMainAlt').addClass('active');
    }, 400);
  });

  $(".menuMain").mouseleave( function() {
    setTimeout(function() {
      $('.headerContent .menuMainAlt, .menuMain, .menuMain .menuMainAlt').removeClass('active');
    }, 400);
  });
}


// Disable scroll when menu is active
$(document).ready(function () {
  $('.menuMainAlt').click(function() {
    $('body').toggleClass('noScroll');
  });
});

// Modifications for inline nav
if (
  (typeof inlineNav !== "undefined" && inlineNav) 
) {
  $('.mainLogo').clone().insertBefore('.headerContent');
  $('.adminBar, .adminBarEdit').insertBefore('#pageHeader');
}