// Wrap donation amounts in a div so we can flexbox
$('body:not(.homepage) .selectDonationAmount').each(function() {
  $(this)
    .find('.donationAmount:not(.donationAmountOther)')
    .wrapAll('<div class="donationAmountsWrapper" />');
});

// Hide any empty things
$('.appealTotaliser, .donationFrequency, .tabs').each(function() {
  const $this = $(this);
  if ($this.children().length === 0) {
    $this.addClass('hidden');
  }
});

// Hide 'payment method' section if there's only a single radio button
// $('section[data-step="payment-method"').each(function() {
//   const $this = $(this);
//   const paymentMethodCount = $this.find('.radio-choice:not(.hidden)').length;

//   // console.log("No. payment methods: " + paymentMethodCount);
//   if(paymentMethodCount === 1) {
//     $this.hide();
//   }
// });

// Hide empty repeat payments element for Aus clients where this doesn't exist
$('.yourDonation > .donationFrequency').each(function() {
  const $this = $(this);
  if ($this.children().length === 1) {
    $this.addClass('hidden');
  }
});

// Check if appeal has hit fundraising goal
$('.post .appealActionsWrapper').each(function() {
  if (
    $(this)
      .find('.appealActions')
      .children().length === 0
  ) {
    $(this).addClass('hidden');
  }
});
